import { PortableText, defaultComponents } from '@portabletext/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const BannerStyles = styled.div`
  background-color: tomato;
  width: 100vw;
  height: 9rem;
  margin: 75px 0 0 0;
  z-index: 1;
  position: fixed;
  left: 0;
  display: grid;
  grid-template-columns: minmax(auto, 1fr);
  place-content: center;
  h3 {
    padding: 0 1rem;
    font-size: 1.7rem;
    text-align: center;
    color: #fff;
    text-transform: smallcaps;
  }
  h3 a {
    color: #fff;
    text-decoration-color: #fff;
  }
  h3 a:hover {
    color: var(--green);
    text-decoration-color: var(--green);
  }
  @media only screen and (max-width: 768px) {
    height: 9rem;
    margin: 9rem 0 0 0;
  }
  @media only screen and (max-width: 450px) {
    height: 100px;
  }
  @media only screen and (max-width: 345px) {
    height: 125px;
  }
`

export default function Banner() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: allSanityBanner {
        nodes {
          id
          _rawMessage
        }
      }
    }
  `)
  const { nodes } = banner
  return (
    <BannerStyles>
      {nodes.map(n => (
        <h3 key={n.id}>
          <PortableText value={n._rawMessage} components={defaultComponents} />
        </h3>
      ))}
    </BannerStyles>
  )
}
